import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import MainWrapper from "../components/main-wrapper";
import Card from "../components/card";
import Title from "../components/title";
import header from "./../assets/headers/seminar.png";
import seminar from "./../assets/imgs/seminar.jpg";
import seminar2 from "./../assets/imgs/seminar2.jpg";

const SeminarScreen = () => {
  const { t } = useTranslation();

  return (
    <MainWrapper header={header} title={t("seminar.title")}>
      <Card isRight image={seminar}>
        <Title title={t("seminar.first.title")} />
        <Text>{t("seminar.first.1")}</Text>
        <Text>{t("seminar.first.2")}</Text>
      </Card>
      <Card image={seminar2}>
        <Text>{t("seminar.second.1")}</Text>
        <Text>{t("seminar.second.2")}</Text>
        <Text>{t("seminar.second.3")}</Text>
      </Card>
    </MainWrapper>
  );
};

const Text = styled.div`
  margin-bottom: 10px;
`;
export default SeminarScreen;
