import React from "react";
import { Router } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import history from "./services/history";
import Routes from "./routes";
import theme from "./theme";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router history={history}>
        <Routes />
      </Router>
    </ThemeProvider>
  );
}
export default App;
