const theme = {
  colors: {
    primary: "#2e6da4",
    secondary: "",
    background: "#f2f2f2",
    border: "#a8a8a8",
    white:'#fff',
    error: '#FF0000',
    black:'#000'
  },
};
export default theme;
