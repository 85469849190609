import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import logo from "./../assets/imgs/logo.png";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

interface Props {
  picture: string;
  title: string;
}

const Header: React.FC<Props> = ({ picture, title }) => {
  const { t } = useTranslation();

  return (
    <Container>
      <MainPicture
        src={picture}
        alt={t("home.castelAlt")}
        effect="blur"
        width="100%"
        height="100%"
      />
      <AboveContent>
        <a href="/">
          <Logo src={logo} alt="Logo" />
        </a>
        <Title>{title}</Title>
      </AboveContent>
      {/* <RightContent>
        <Languages />
      </RightContent> */}
    </Container>
  );
};
const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  min-height: 400px;
  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    min-height: 142px;
  }
`;
const AboveContent = styled.div`
  z-index: 10;
  position: absolute;
  top: 0;
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const Title = styled.h1`
  text-align: center;
  font-style: italic;
  font-size: 40px;
  font-family: Brush Script MT, cursive;
  font-weight: 200;
  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    font-size: 20px;
    margin-top: -5px;
  }
`;
const Logo = styled.img`
  margin-top: 20%;
  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    margin-top: 0px;
    max-height: 100px;
  }
`;
const MainPicture = styled(LazyLoadImage)`
  width: 100%;
  position: absolute;
  height: 400px;
  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    height: 142px;
  }
  object-fit: cover;
  mask-image: linear-gradient(
    to left,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0.3) 35%,
    rgba(0, 0, 0, 0.3) 65%,
    rgba(0, 0, 0, 1) 100%
  );
  top: 0;
`;

export default Header;
