import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import MainWrapper from "../components/main-wrapper";
import header from "./../assets/headers/garden-vue.jpg";
import background404 from "./../assets/imgs/background-404.png";

const NotFoundScreen = () => {
  const { t } = useTranslation();

  return (
    <MainWrapper header={header} title={t("404.title")}>
      <MainText>404</MainText>
      <SubText>{t("404.content")}</SubText>
    </MainWrapper>
  );
};

const MainText = styled.div`
  font-family: "Titillium Web", sans-serif;
  font-size: 186px;
  font-weight: 900;
  margin: 0px;
  text-transform: uppercase;
  background: url(${background404});
  -webkit-background-clip: text;
  margin: auto;
  -webkit-text-fill-color: transparent;
  background-size: cover;
  background-position: center;
`;
const SubText = styled.div`
  margin-top: 10px;
  margin: auto;
  font-size: 22px;
`;
export default NotFoundScreen;
