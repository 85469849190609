import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import MainWrapper from "../components/main-wrapper";
import Card from "../components/card";
import Title from "../components/title";
import header from "./../assets/headers/reception.png";
import reception8 from "./../assets/imgs/reception8.jpg";
import reception3 from "./../assets/imgs/reception3.jpg";
import reception7 from "./../assets/imgs/reception7.jpg";

const ReceptionScreen = () => {
  const { t } = useTranslation();

  return (
    <MainWrapper header={header} title={t("reception.title")}>
      <Card isRight image={reception7}>
        <Title title={t("reception.aReception.title")} />
        <Text>{t("reception.aReception.1")}</Text>
        <Text>{t("reception.aReception.2")}</Text>
        <Text>{t("reception.aReception.3")}</Text>
        <Text>{t("reception.aReception.4")}</Text>
        <Text>{t("reception.aReception.5")}</Text>
      </Card>
      <Card image={reception3}>
        <Title title={t("reception.ideal.title")} />
        <Text>{t("reception.ideal.1")}</Text>
        <Highlight>{t("reception.ideal.2")}</Highlight>
        <Text>{t("reception.ideal.3")}</Text>
        <Text>{t("reception.ideal.4")}</Text>
      </Card>
      <Card image={reception8} isRight>
        <Title title={t("reception.ideal.secondTitle")} />
        <Text>{t("reception.ideal.5")}</Text>
        <Text>{t("reception.ideal.6")}</Text>
        <Text>{t("reception.ideal.7")}</Text>
        <Text>{t("reception.ideal.8")}</Text>
        <Text>{t("reception.ideal.9")}</Text>
        <Text>{t("reception.ideal.10")}</Text>
        <Highlight>{t("reception.ideal.11")}</Highlight>
      </Card>
    </MainWrapper>
  );
};

const Text = styled.div`
  margin-bottom: 10px;
`;
const Highlight = styled.b`
  font-size: 22px;
  margin-right: 5px;
`;
export default ReceptionScreen;
