import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Header from "../components/header";
import Card from "../components/card";
import Title from "../components/title";
import header from "./../assets/headers/castel.jpg";
import castelBack from "./../assets/imgs/castel-back.jpg";
import castelFromLogis from "./../assets/imgs/castel-from-logis.png";

interface Props {
  reason: string;
}

const Reason: React.FC<Props> = ({ reason }) => {
  const { t } = useTranslation();
  return (
    <StyledReason>
      <Highlight>{t(`castel.reason${reason}.highlight`)}</Highlight>
      <>{t(`castel.reason${reason}.content`)}</>
    </StyledReason>
  );
};

const CastelScreen = () => {
  const { t } = useTranslation();

  return (
    <MainContainer>
      <Header picture={header} title={t("castel.title")} />
      <Content>
        <Card isRight image={castelFromLogis}>
          <Title title={t("castel.reasonsTitle")} />
          <Reason reason="1" />
          <Reason reason="2" />
          <Reason reason="3" />
          <Reason reason="4" />
          <Reason reason="5" />
          <Reason reason="6" />
        </Card>
        <Card image={castelBack}>
          <Citation>{t("castel.citation")}</Citation>
          <Text>{t("castel.author")}</Text>
        </Card>
      </Content>
    </MainContainer>
  );
};

const Text = styled.div`
  margin-bottom: 10px;
`;
const StyledReason = styled.div`
  margin-bottom: 10px;
`;
const Citation = styled(Text)`
  font-style: italic;
`;
const Highlight = styled.b`
  font-size: 22px;
  margin-right: 5px;
`;
const Content = styled.div`
  display: flex;
  width: 70%;
  margin-top: 10px;
  flex-direction: column;
  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    width: 95%;
  }
`;
const MainContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: Helvetica New;
  font-weight: 200;
`;
export default CastelScreen;
