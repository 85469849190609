import React, { useState, useContext } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled, { ThemeContext } from "styled-components";
import MainWrapper from "../components/main-wrapper";
import Input from "../components/input";
import { config } from "./../constants.js";
import header from "./../assets/headers/contact.png";
import emailjs from "@emailjs/browser";

interface FormData {
  firstName: string;
  lastName: string;
  address: string;
  postalCode: string;
  city: string;
  phone: string;
  email: string;
  attendings: string;
  eventType: string;
  date: string;
  message: string;
}

const ContactScreen = () => {
  const { colors } = useContext(ThemeContext);
  const { t } = useTranslation();
  const buttonTranslation = t("contact.send");
  const { register, errors, reset } = useForm();
  const [message, setMessage] = useState(undefined);
  const [hasEvent, setHasEvent] = useState(false);

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    emailjs
      .sendForm(
        config.EMAIL_SERVICE_ID,
        config.EMAIL_TEMPLATE_ID,
        e.currentTarget,
        config.EMAIL_PUBLIC_KEY
      )
      .then(
        (result) => {
          setMessage(t("contact.thanks"));
          reset();
        },
        (error) => {
          setMessage(t("contact.error"));
        }
      );
  };

  return (
    <MainWrapper header={header} title={t("contact.title")}>
      <FormContainer onSubmit={onSubmit}>
        <Row>
          <Input
            name="firstName"
            required
            splitted
            placeholder={t("contact.firstName")}
            register={register}
            hasError={errors.firstName}
          />
          <Input
            splitted
            name="lastName"
            required
            placeholder={t("contact.lastName")}
            register={register}
            hasError={errors.firstName}
          />
        </Row>
        {(errors.firstName || errors.lastName) && (
          <Error>{t("contact.errors.name")}</Error>
        )}
        <Input
          name="address"
          placeholder={t("contact.address")}
          register={register}
        />
        <Row>
          <Input
            splitted
            name="postalCode"
            placeholder={t("contact.postalCode")}
            register={register}
          />
          <Input
            splitted
            name="city"
            placeholder={t("contact.city")}
            register={register}
          />
        </Row>
        <Row>
          <Input
            splitted
            name="phone"
            placeholder={t("contact.phone")}
            register={register}
          />
          <Input
            splitted
            name="email"
            type="email"
            hasError={errors.email}
            register={register}
            required
            placeholder={t("contact.email")}
          />
        </Row>
        {errors.email && <Error>{t("contact.errors.email")}</Error>}
        <Row>
          <Input
            splitted
            register={register}
            name="attendings"
            placeholder={t("contact.attendings")}
          />
          <Select
            name="eventType"
            ref={register}
            placeholder="test"
            color={hasEvent ? colors.black : colors.border}
            onChange={() => setHasEvent(true)}
          >
            <option disabled selected>
              {t("contact.eventType")}
            </option>
            <option value="wedding">{t("contact.types.wedding")}</option>
            <option value="seminary">{t("contact.types.seminary")}</option>
            <option value="reception">{t("contact.types.reception")}</option>
          </Select>
        </Row>
        <Row>
          <Input
            splitted
            register={register}
            hasError={errors.from}
            name="date"
            type="date"
            placeholder={t("contact.date")}
            required
          />
        </Row>
        <TextArea
          rows={10}
          ref={register}
          name="message"
          id="message"
          placeholder={t("contact.message")}
        />
        {message && <Message>{message}</Message>}
        <Button type="submit" value={buttonTranslation} />
      </FormContainer>
    </MainWrapper>
  );
};

const Error = styled.div`
  color: red;
  font-size: 14px;
`;
const Message = styled.div`
  color: black;
  font-size: 16px;
`;

const FormContainer = styled.form`
  display: flex;
  width: 75%;
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 20px;
  margin-bottom: 20px;
  margin: auto;
`;
const TextArea = styled.textarea`
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid ${({ theme }): string => theme.colors.black};
  font-size: 16px;
  border-radius: 4px;
  box-sizing: border-box;
  font-family: Helvetica New;
  font-weight: 200;
  @media only screen and (max-width: 768px) {
    width: 97%;
    min-width: 97%;
    max-width: 97%;
  }
`;
const Row = styled.div`
  flex-direction: row;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
  display: flex;
  width: 100%;
  justify-content: space-between;
`;
const Button = styled.input`
  background-color: ${({ theme }): string => theme.colors.primary};
  font-size: 20px;
  color: ${({ theme }): string => theme.colors.white};
  letter-spacing: 1px;
  padding: 8px 12px;
  border-radius: 5px;
  line-height: 1.5;
  margin-top: 10px;
  margin-bottom: 20px;
  text-decoration: none;
  width: 100%;
  font-family: Helvetica New;
  font-weight: 200;
  @media only screen and (max-width: 768px) {
    width: 97%;
  }
`;
const Select = styled.select<{ color: string }>`
  width: 49%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid ${({ theme }): string => theme.colors.black};
  font-size: 16px;
  border-radius: 4px;
  box-sizing: border-box;
  font-family: Helvetica New;
  font-weight: 200;
  @media only screen and (max-width: 768px) {
    width: 97%;
    min-width: 97%;
    max-width: 97%;
    margin-left: 6px;
  }
  color: ${({ color }) => color};
  background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+)
    no-repeat;
  background-position: right 7px top;
  -moz-appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  &:focus {
    outline: none !important;
    border: 1px solid ${({ theme }): string => theme.colors.border};
    box-shadow: 0 0 5px ${({ theme }): string => theme.colors.border};
    color: ${({ theme }): string => theme.colors.black};
  }
`;
export default ContactScreen;
