import { useEffect, useState } from "react";

import bedroom from "./../assets/imgs/bedroom.jpg";
import castel from "./../assets/imgs/castel.jpg";
import castelBack from "./../assets/imgs/castel-back.jpg";
import castelFromSky from "./../assets/imgs/castelFromSky.png";
import coktail from "./../assets/imgs/coktail.jpg";
import diner from "./../assets/imgs/diner.jpg";
import flowers from "./../assets/imgs/flowers.jpg";
import fromWindow from "./../assets/imgs/from-window.jpg";
import gardenVue from "./../assets/imgs/garden-vue.jpg";
import mainBedroom from "./../assets/imgs/mainBedroom.jpg";
import reception1 from "./../assets/imgs/reception1.jpg";
import reception2 from "./../assets/imgs/reception2.jpg";
import reception3 from "./../assets/imgs/reception3.jpg";
import reception7 from "./../assets/imgs/reception7.jpg";
import reception8 from "./../assets/imgs/reception8.jpg";
import seminary from "./../assets/imgs/seminary.jpg";
import seminar from "./../assets/imgs/seminar.jpg";
import seminar2 from "./../assets/imgs/seminar2.jpg";
import seminar3 from "./../assets/imgs/seminar3.jpg";
import sign from "./../assets/imgs/sign.jpg";
import snow from "./../assets/imgs/snow.png";
import summerCastel from "./../assets/imgs/summer-castel.jpg";
import summer from "./../assets/imgs/summer.jpg";
import sunDown from "./../assets/imgs/sun-down.png";
import welcomeView from "./../assets/imgs/welcomeView.jpg";
import sunDown2 from "./../assets/imgs/sunDown.jpg";
import table from "./../assets/imgs/table.jpg";
import seminary2 from "./../assets/imgs/seminary2.jpg";
import reception5 from "./../assets/imgs/reception5.jpg";
import room from "./../assets/imgs/room.jpg";
import room2 from "./../assets/imgs/room2.jpg";
import reception6 from "./../assets/imgs/reception6.jpg";
import cars from "./../assets/imgs/cars.jpg";
import roses from "./../assets/imgs/roses.jpg";
import summerGarden from "./../assets/imgs/summerGarden.jpg";
import weddingHorse from "./../assets/imgs/weddingHorse.jpg";
import mongolfiere from "./../assets/imgs/mongolfiere.jpg";
import dorm from "./../assets/imgs/dorm.jpg";
import castel2 from "./../assets/imgs/castel2.jpg";
import outsideBedroom from "./../assets/imgs/outsideBedroom.jpg";
import castel3 from "./../assets/imgs/castel3.jpg";
import bedroom2 from "./../assets/imgs/bedroom2.jpg";
import sanitary from "./../assets/imgs/sanitary.jpg";
import restauration from "./../assets/imgs/restauration.jpg";
import potager from "./../assets/imgs/potager.jpg";

export const gallery = [
  {
    src: table,
    width: 4,
    height: 4,
    source: table,
  },

  {
    src: summerCastel,
    width: 4,
    height: 3,
    source: summerCastel,
  },
  {
    src: reception5,
    width: 4,
    height: 3,
    source: reception5,
  },
  {
    src: potager,
    width: 4,
    height: 3,
    source: potager,
  },
  {
    src: reception8,
    width: 4,
    height: 3,
    source: reception8,
  },
  {
    src: room,
    width: 7,
    height: 3,
    source: room,
  },
  {
    src: weddingHorse,
    width: 6,
    height: 4,
    source: weddingHorse,
  },
  {
    src: castel2,
    width: 6,
    height: 4,
    source: castel2,
  },
  {
    src: summer,
    width: 4,
    height: 3,
    source: summer,
  },
  {
    src: snow,
    width: 5,
    height: 3,
    source: snow,
  },
  {
    src: summerGarden,
    width: 4,
    height: 3,
    source: summerGarden,
  },
  {
    src: seminary,
    width: 6,
    height: 5,
    source: seminary,
  },
  {
    src: seminar,
    width: 6,
    height: 5,
    source: seminar,
  },
  {
    src: seminar2,
    width: 6,
    height: 5,
    source: seminar2,
  },
  {
    src: seminar3,
    width: 6,
    height: 5,
    source: seminar3,
  },
  {
    src: sunDown,
    width: 6,
    height: 3,
    source: sunDown,
  },
  {
    src: reception6,
    width: 6,
    height: 3,
    source: reception6,
  },
  {
    src: cars,
    width: 5,
    height: 6,
    source: cars,
  },
  {
    src: room2,
    width: 6,
    height: 4,
    source: room2,
  },
  {
    src: welcomeView,
    width: 4,
    height: 3,
    source: welcomeView,
  },
  {
    src: castelFromSky,
    width: 5,
    height: 3,
    source: castelFromSky,
  },
  {
    src: castel,
    width: 8,
    height: 3,
    source: castel,
  },
  {
    src: outsideBedroom,
    width: 4,
    height: 3,
    source: outsideBedroom,
  },
  {
    src: bedroom2,
    width: 4,
    height: 3,
    source: bedroom2,
  },
  {
    src: bedroom,
    width: 4,
    height: 3,
    source: bedroom,
  },
  {
    src: dorm,
    width: 4,
    height: 3,
    source: dorm,
  },
  {
    src: coktail,
    width: 4,
    height: 3,
    source: coktail,
  },
  {
    src: diner,
    width: 4,
    height: 3,
    source: diner,
  },
  {
    src: flowers,
    width: 4,
    height: 3,
    source: flowers,
  },
  {
    src: seminary2,
    width: 4,
    height: 3,
    source: seminary2,
  },
  {
    src: fromWindow,
    width: 6,
    height: 8,
    source: fromWindow,
  },
  {
    src: roses,
    width: 4,
    height: 6,
    source: roses,
  },
  {
    src: gardenVue,
    width: 4,
    height: 3,
    source: gardenVue,
  },
  {
    src: mainBedroom,
    width: 4,
    height: 4,
    source: mainBedroom,
  },
  {
    src: reception1,
    width: 4,
    height: 3,
    source: reception1,
  },
  {
    src: reception2,
    width: 4,
    height: 6,
    source: reception2,
  },
  {
    src: castel3,
    width: 5,
    height: 4,
    source: castel3,
  },
  {
    src: sunDown2,
    width: 4,
    height: 3,
    source: sunDown2,
  },
  {
    src: reception3,
    width: 4,
    height: 3,
    source: reception3,
  },
  {
    src: reception7,
    width: 4,
    height: 3,
    source: reception7,
  },
  {
    src: mongolfiere,
    width: 4,
    height: 4,
    source: mongolfiere,
  },
  {
    src: sign,
    width: 5,
    height: 3,
    source: sign,
  },
  {
    src: castelBack,
    width: 5,
    height: 3,
    source: castelBack,
  },
  {
    src: sanitary,
    width: 5,
    height: 3,
    source: sanitary,
  },
  {
    src: restauration,
    width: 5,
    height: 3,
    source: restauration,
  },
];

const useCheckMobileScreen = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  return width <= 768;
};

export default useCheckMobileScreen;
