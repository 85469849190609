import React from "react";
import { Switch, Route } from "react-router-dom";
import HomeScreen from "../pages/home.tsx";
import ContactScreen from "../pages/contact.tsx";
import ReceptionScreen from "../pages/reception.tsx";
import NotFoundScreen from "../pages/404.tsx";
import AccessScreen from "../pages/access.tsx";
import GalleryScreen from "../pages/gallery.tsx";
import CastelScreen from "../pages/castel.tsx";
import VirtualScreen from "../pages/virtual.tsx";
import BedroomsScreen from "../pages/bedrooms.tsx";
import RoomsScreen from "../pages/rooms.tsx";
import SeminarScreen from "../pages/seminar.tsx";
import ArrangementsScreen from "../pages/arrangements.tsx";
import { config } from "./../constants.js";

export default function Routes() {
  const prefix = config.prefix;
  return (
    <Switch>
      <Route path={prefix + "/"} exact component={HomeScreen} />
      <Route path={prefix + "/contact"} exact component={ContactScreen} />
      <Route path={prefix + "/reception"} exact component={ReceptionScreen} />
      <Route path={prefix + "/access"} exact component={AccessScreen} />
      <Route path={prefix + "/gallery"} exact component={GalleryScreen} />
      <Route path={prefix + "/castel"} exact component={CastelScreen} />
      <Route path={prefix + "/virtual"} exact component={VirtualScreen} />
      <Route path={prefix + "/bedrooms"} exact component={BedroomsScreen} />
      <Route path={prefix + "/seminar"} exact component={SeminarScreen} />
      <Route path={prefix + "/rooms"} exact component={RoomsScreen} />
      <Route
        path={prefix + "/arrangements"}
        exact
        component={ArrangementsScreen}
      />
      {/* redirect user to Home page if route does not exist */}
      <Route component={NotFoundScreen} />
    </Switch>
  );
}
