import React, { useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import MainWrapper from "../components/main-wrapper";
import header from "./../assets/imgs/summer-castel2.png";
import Gallery from "react-photo-gallery";
import { gallery } from "./../services/utils";
import Carousel, { Modal, ModalGateway } from "react-images";

const GalleryScreen = () => {
  const { t } = useTranslation();

  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = (): void => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  return (
    <MainWrapper header={header} title={t("gallery.title")}>
      <Gallery photos={gallery} onClick={openLightbox} />
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel currentIndex={currentImage} views={gallery} />
          </Modal>
        ) : null}
      </ModalGateway>
    </MainWrapper>
  );
};

export default GalleryScreen;
