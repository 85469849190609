import React from "react";
import styled from "styled-components";

interface Props {
  splitted?: boolean;
  name: string;
  placeholder: string;
  required?: boolean;
  hasError?: boolean;
  register: any;
  type?: string;
}

const renderContent = (
  name: string,
  placeholder: string,
  required: boolean,
  register: any,
  hasError: boolean,
  type?: string
) => {
  return (
    <>
      <StyledInput
        type={type ? type : "text"}
        name={name}
        isDate={type === "email"}
        placeholder={placeholder}
        ref={register({
          required: required,
          pattern:
            type === "email"
              ? {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                }
              : {},
        })}
        hasError={hasError}
      />
      {required && <Required hasError={hasError}>*</Required>}
    </>
  );
};

const Input: React.FC<Props> = ({
  splitted = false,
  name,
  placeholder,
  required = false,
  register,
  hasError = false,
  type,
}) => {
  return (
    <>
      {splitted ? (
        <SplittedContainer>
          {renderContent(name, placeholder, required, register, hasError, type)}
        </SplittedContainer>
      ) : (
        <SimpleContainer>
          {renderContent(name, placeholder, required, register, hasError, type)}
        </SimpleContainer>
      )}
    </>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  @media only screen and (max-width: 768px) {
    margin: auto;
  }
`;
const SimpleContainer = styled(Container)`
  width: 100%;
  @media only screen and (max-width: 768px) {
    width: 97%;
  }
`;
const SplittedContainer = styled(Container)`
  width: 49%;
  @media only screen and (max-width: 768px) {
    width: 97%;
  }
`;
const Required = styled.div<{ hasError: boolean }>`
  position: absolute;
  margin-left: 415px;
  margin-top: 23px;
  color: ${({ hasError, theme }): string =>
    hasError ? theme.colors.red : theme.colors.black};
  @media only screen and (max-width: 768px) {
    margin-left: 93%;
    margin-top: 23px;
  }
`;
const StyledInput = styled.input<{ hasError: boolean; isDate: boolean }>`
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid
    ${({ theme, hasError }): string =>
      hasError ? theme.colors.red : theme.colors.black};
  font-size: 16px;
  border-radius: 4px;
  box-sizing: border-box;
  font-family: Helvetica New;
  font-weight: 200;
  ::placeholder {
    color: ${({ hasError, theme }): string =>
      hasError ? theme.colors.red : theme.colors.border};
  }
  &:focus {
    outline: none !important;
    border: 1px solid
      ${({ hasError, theme }): string =>
        hasError ? theme.colors.red : theme.colors.black};
    box-shadow: 0 0 5px ${({ theme }): string => theme.colors.border};
    color: ${({ hasError, theme }): string =>
      hasError ? theme.colors.red : theme.colors.black};
  }
  color: ${({ hasError, theme }): string =>
    hasError ? theme.colors.red : theme.colors.black};
`;

export default Input;
