import React from "react";
import { useTranslation } from "react-i18next";
import GoogleMapReact from "google-map-react";
import styled from "styled-components";
import MainWrapper from "../components/main-wrapper";
import Card from "../components/card";
import Title from "../components/title";
import header from "./../assets/headers/sun-down.png";
import image from "./../assets/imgs/cars.jpg";

interface Props {
  translateKey: string;
  items: string[];
}

const Info: React.FC<Props> = ({ translateKey, items }) => {
  const { t } = useTranslation();

  return (
    <div>
      <Title title={t("access." + translateKey + ".title")} />
      <List>
        {items.map((i) => (
          <li key={i + "/" + translateKey}>
            {t("access." + translateKey + "." + i)}
          </li>
        ))}
      </List>
    </div>
  );
};

const AccessScreen = () => {
  // TODO trouver une meilleure image de header
  const { t } = useTranslation();

  const martignePosition = {
    lat: 47.94023,
    lng: -0.254999,
  };

  const renderMarkers = (map: any, maps: any) => {
    let marker = new maps.Marker({
      position: { ...martignePosition },
      map,
    });
    return marker;
  };

  return (
    <MainWrapper header={header} title={t("access.title")}>
      <Card image={image} isRight>
        <Infos>
          <Title title={t("access.invited.title")} />
          <Text>{t("access.invited.content")}</Text>
          <Info translateKey="byCar" items={["1", "2", "3", "4", "5"]} />
          <Info translateKey="byTrain" items={["1", "2", "3", "4"]} />
        </Infos>
      </Card>
      <Map>
        <GoogleMapReact
          bootstrapURLKeys={{
            key: "AIzaSyDmGB3sB7SRpghvJC_wx-zB8Z9ZSIfOuyM",
          }}
          onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
          defaultCenter={martignePosition}
          defaultZoom={12}
        ></GoogleMapReact>
      </Map>
    </MainWrapper>
  );
};

const Text = styled.div`
  margin-bottom: 10px;
`;
const Infos = styled.div`
  @media only screen and (max-width: 768px) {
    padding-left: 10px;
    padding-right: 10px;
  }
`;
const List = styled.ul`
  list-style-type: circle;
`;
const Map = styled.div`
  height: 350px;
  width: 100%;
  margin: auto;
  margin-top: 15px;
  margin-bottom: 15px;
`;
export default AccessScreen;
