import React from "react";
import styled from "styled-components";
import { LazyLoadImage } from "react-lazy-load-image-component";

interface Props {
  image: string;
  isRight?: boolean;
}

const Card: React.FC<Props> = ({ image, isRight = false, children }) => {
  return (
    <Row>
      {isRight && <Image src={image} effect="blur" />}
      <Paragraph isRight={isRight}>{children}</Paragraph>
      {!isRight && <Image src={image} effect="blur" />}
    </Row>
  );
};

const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    flex-direction: column;
  }
`;
const Image = styled(LazyLoadImage)`
  border-radius: 5px;
  width: 700px;
  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    width: 100%;
  }
`;
const Paragraph = styled.div<{ isRight: boolean }>`
  width: 50%;
  margin-top: auto;
  margin-bottom: auto;
  flex-direction: column;
  margin-left: ${({ isRight }): string => (isRight ? "-50" : "0")}px;
  margin-right: ${({ isRight }): string => (isRight ? "0" : "-50")}px;
  padding: 15px;
  justify-content: center;
  background-color: white;
  elevation: 15;
  z-index: 15;
  background-color: ${({ theme }): string => theme.colors.background};
  border: 1px solid ${({ theme }): string => theme.colors.border};
  border-radius: 5px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    width: 92%;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
`;
export default Card;
