import React from "react";
import styled from "styled-components";
import Header from "../components/header";

interface Props {
  title: string;
  header: string;
  centered?: boolean;
}

const MainWrapper: React.FC<Props> = ({
  title,
  header,
  centered = false,
  children,
}) => {
  return (
    <MainContainer>
      <Header picture={header} title={title} />
      <Content centered={centered}>{children}</Content>
    </MainContainer>
  );
};

const Content = styled.div<{ centered: boolean }>`
  display: flex;
  width: 70%;
  margin-top: 10px;
  flex-direction: column;
  ${({ centered }): string =>
    centered ? "justify-content:center; align-items: center;" : ""}
  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    width: 95%;
  }
`;
const MainContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: Helvetica New;
  font-weight: 200;
`;
export default MainWrapper;
