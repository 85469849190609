import React from "react";
import { useTranslation } from "react-i18next";
import MainWrapper from "../components/main-wrapper";
import header from "./../assets/headers/virtual.png";
import martigneVideo from "./../assets/video/martigne.mov";

const VirtualScreen = () => {
  const { t } = useTranslation();
  return (
    <MainWrapper header={header} title={t("virtual.title")} centered>
      <video id="video" autoPlay loop width="1000" height="500">
        <source src={martigneVideo} type="video/mp4" />
      </video>
    </MainWrapper>
  );
};
export default VirtualScreen;
