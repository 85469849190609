import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { chunk } from "lodash";
import useCheckMobileScreen from "./../services/utils";
import Header from "../components/header";
import MenuItem from "../components/menu-item";
import header from "./../assets/headers/home.jpg";

// menu items
import castel from "./../assets/menu-items/castel.jpg";
import flowers from "./../assets/menu-items/flowers.jpg";
import receptions from "./../assets/menu-items/reception.png";
import seminary from "./../assets/menu-items/seminary.png";
import balloon from "./../assets/menu-items/balloon.png";
import bedroom from "./../assets/menu-items/bedroom.png";
import coktail from "./../assets/menu-items/coktail.jpg";
import castelFromSky from "./../assets/menu-items/gallery.png";
import potager from "./../assets/menu-items/potager.jpg";
import sign from "./../assets/menu-items/sign.jpg";

const HomeScreen = () => {
  const { t } = useTranslation();
  const isMobile = useCheckMobileScreen();

  const numberOfColumns = isMobile ? 1 : 5;

  const menu = [
    {
      image: flowers,
      text: t("home.menu.contact"),
      link: "contact",
    },
    {
      image: receptions,
      text: t("home.menu.receptions"),
      link: "reception",
    },
    {
      image: seminary,
      text: t("home.menu.seminary"),
      link: "seminar",
    },
    {
      image: potager,
      text: t("home.menu.rooms"),
      link: "rooms",
    },
    {
      image: bedroom,
      text: t("home.menu.bedrooms"),
      link: "bedrooms",
    },
    {
      image: castel,
      text: t("home.menu.castel"),
      link: "castel",
    },
    {
      image: coktail,
      text: t("home.menu.arrangement"),
      link: "arrangements",
    },
    {
      image: castelFromSky,
      text: t("home.menu.gallery"),
      link: "gallery",
    },
    {
      image: balloon,
      text: t("home.menu.virtual"),
      link: "virtual",
    },
    {
      image: sign,
      text: t("home.menu.access"),
      link: "access",
    },
  ];

  const chunked = chunk(menu, numberOfColumns);

  return (
    <MainContainer>
      <Header picture={header} title={t("home.title")} />
      <Menu>
        {chunked.map((chunk) => (
          <Row>
            {chunk.map((elem) => (
              <MenuItem {...elem} />
            ))}
          </Row>
        ))}
      </Menu>
    </MainContainer>
  );
};
const MainContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
const Row = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
`;
const Menu = styled.div`
  width: 100%;
`;

export default HomeScreen;
