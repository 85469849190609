const prod = {
  url: "https://martigne-le-logis.fr",
  prefix: "",
  API_PATH: "/api/contact/index.php",
  EMAIL_SERVICE_ID: "service_kovnp6p",
  EMAIL_TEMPLATE_ID: "template_fjx9n3c",
  EMAIL_PUBLIC_KEY: "un0fXG1cbIrKa-8Vc",
};
const dev = {
  url: "http://localhost:3000",
  prefix: "",
  API_PATH: "/api/contact/index.php",
  EMAIL_SERVICE_ID: "service_kovnp6p",
  EMAIL_TEMPLATE_ID: "template_fjx9n3c",
  EMAIL_PUBLIC_KEY: "un0fXG1cbIrKa-8Vc",
};
export const config = process.env.NODE_ENV === "development" ? dev : prod;
