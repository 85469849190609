import React from "react";
import styled from "styled-components";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

interface Props {
  image: string;
  text: string;
  link: string;
}

const MenuItem: React.FC<Props> = ({ image, text, link }) => {
  return (
    <Container href={link}>
      <MenuImage>
        <LazyLoadImage src={image} effect="blur" width="100%" height="100%" />
      </MenuImage>
      <AboveContent>
        <MenuText>{text}</MenuText>
      </AboveContent>
    </Container>
  );
};

const standardSize = 300;
const mobileHeight = 250;

const size = `
  width: ${standardSize}px;
  height: ${standardSize}px;
  @media only screen and (max-width: 768px) {
    height: ${mobileHeight}px;
   }
 `;

const MenuImage = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
  transition: 0.5s;
  &:hover {
    opacity: 0.5;
  }
`;
const MenuText = styled.div`
  text-decoration: none;
  font-family: Brush Script MT, cursive;
  font-weight: 200;
  color: white;
  font-size: 38px;
  width: 100%;
  text-align: center;
  padding-right: 10px;
  padding-left: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  @media only screen and (max-width: 768px) {
    font-size: 28px;
  }
`;
const AboveContent = styled.div`
  position: absolute;
  align-items: center;
  justify-content: center;
`;

const Container = styled.a`
  position: relative;
  display: flex;
  flex: 1;
  cursor: pointer;
  align-items: center;
  ${size}
  justify-content: center;
  margin-top: 5px;
  margin-left: 5px;
  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    margin-right: 5px;
  }
  transition: 0.5s;
  &:hover {
    background-color: rgba(0, 0, 0, 0.3);
  }
`;
export default MenuItem;
