import React from "react";
import styled from "styled-components";

interface Props {
  title: string;
}
const Title: React.FC<Props> = ({ title }) => (
  <>
    <Text>{title}</Text>
    <Separator />
  </>
);

const Text = styled.div`
  margin-bottom: 10px;
  font-size: 26px;
  font-family: Brush Script MT, cursive;
  font-weight: 200;
`;
const Separator = styled.div`
  background-color: ${({ theme }): string => theme.colors.primary};
  margin-bottom: 10px;
  height: 3px;
  width: 20%;
`;
export default Title;
