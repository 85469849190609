import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import MainWrapper from "../components/main-wrapper";
import Card from "../components/card";
import Title from "../components/title";
import header from "./../assets/headers/rooms.png";
import gardenVue from "./../assets/imgs/garden-vue.jpg";
import potager from "./../assets/imgs/potager.jpg";
import solesmes from "./../assets/imgs/solesmes.jpg";

const RoomsScreen = () => {
  const { t } = useTranslation();

  return (
    <MainWrapper header={header} title={t("rooms.title")}>
      <Card isRight image={gardenVue}>
        <Title title={t("rooms.first.title")} />
        <Text>{t("rooms.first.1")}</Text>
        <Text>{t("rooms.first.2")}</Text>
      </Card>
      <Card image={potager}>
        <Title title={t("rooms.second.title")} />
        <Text>{t("rooms.second.1")}</Text>
        <Text>{t("rooms.second.2")}</Text>
      </Card>
      <Card image={solesmes} isRight>
        <Title title={t("rooms.third.title")} />
        <Text>{t("rooms.third.1")}</Text>
      </Card>
    </MainWrapper>
  );
};

const Text = styled.div`
  margin-bottom: 10px;
`;
export default RoomsScreen;
